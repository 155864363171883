import { Col, Collapse, Divider, Row } from "antd";
import { PageProps, graphql } from "gatsby";
import * as React from "react";
import { Timeline } from "react-twitter-widgets";
import Page from "../components/page";
import logo from "../images/logo.png";
import logoWebP from "../images/logo.webp";

interface TopicsProps {
  dataSource: Queries.IndexPageQuery;
}

const Topics = (props: TopicsProps) => (
  <Collapse
    bordered={false}
    defaultActiveKey={props.dataSource.allMicrocmsNews.edges.flatMap(
      (it) => `${it.node.id}`
    )}
  >
    {props.dataSource.allMicrocmsNews.edges.map((it) => {
      return (
        <Collapse.Panel header={it.node.title} key={it.node.id}>
          <div
            dangerouslySetInnerHTML={{ __html: it.node.content || "No Data" }}
          />
        </Collapse.Panel>
      );
    })}
  </Collapse>
);

// markup
const IndexPage = (props: PageProps<Queries.IndexPageQuery>) => {
  return (
    <Page title="朝鮮カラオケ大会" ignoreContentPaddingTop={true}>
      <section
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          margin: 0,
          padding: 0,
          height: "100vh",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50vmin",
            maxHeight: "650px",
          }}
        >
          <picture>
            <source srcSet={logoWebP} type="image/webp" />
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={logo}
              alt="조선화면반주음악대회"
              width="650"
              height="650"
            />
          </picture>
          <div
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "6vmin",
              verticalAlign: "bottom",
              marginTop: "0.5em",
            }}
          >
            朝鮮カラオケ大会
          </div>
          <div
            style={{
              fontSize: "4.8vmin",
              color: "white",
              fontFamily: "kpkwamyB",
            }}
          >
            조선화면반주음악대회
          </div>
        </div>
      </section>
      <Row gutter={[16, 16]}>
        <Col md={12} style={{ width: "100%" }}>
          <Divider orientation="left">Tweets</Divider>
          <Timeline
            dataSource={{ sourceType: "profile", screenName: "dprk_karaoke" }}
            options={{ height: 600 }}
          />
        </Col>
        <Col md={12} style={{ width: "100%" }}>
          <Divider orientation="left">Topics</Divider>
          <Topics dataSource={props.data} />
        </Col>
      </Row>
    </Page>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage {
    allMicrocmsNews(sort: { fields: [target_date], order: DESC }) {
      edges {
        node {
          id
          title
          category
          content
          createdAt
          target_date
        }
      }
    }
  }
`;
